@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

/* .forumMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.forumTitle {
    font-family: quicksand;
    align-self: center;
    font-size: 2.5rem;
    color: #012753;
    margin: 15px 10px;
    padding: 10px;
}


@media (max-width: 768px) {
    .forumMain {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
    }

} */



.forumMain {
    background-image: url("assets/chatterBg3.svg");
    background-repeat: repeat; /* Make the background image repeat */
    background-size: 500px 500px; /* Set the size of the background image */
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f2f5;
    padding: 20px;
}

.forumTitle {
    font-family: 'Quicksand', sans-serif;
    font-size: 2rem; /* Reduce font size */
    color: #012753; 
    margin: 10px 0;
}

.forumTitle {
    font-family: quicksand;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}

.forumTitle2 {
    font-family: quicksand;
    font-size: 1.5rem;
    color: #012753;
    margin: 0px 10px;
}

.forumBox {
    width: 100%;
    max-width: 600px;
}

.forumPost {
    background-color: #ffffff; /* Change to white background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    margin-bottom: 20px; /* Add some space between posts */
    padding: 20px; /* Add padding */
}

.forumPost > div {
    margin-bottom: 10px; /* Add space between post elements */
}

.clickText {
    color: #1877f2; 
    cursor: pointer;
}

.clickText2 {
    color: #AABFD7; 
    cursor: pointer;
}

.clickText:hover {
    transform: translateY(-0.25vh);
    color: #012753;
}

.clickText2:hover {
    transform: translateY(-0.25vh);
    color: #012753;
}

.replyTextForum {
    color: white;
    font-weight: bold;
    margin-bottom: 5px; /* Add space between replies */
}

.forumPost > .postContent {
    font-size: 1.2rem; /* Adjust font size for post content */
}

.postHeader {
    color: #012753;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px; /* Add space between header and content */
}

.posterInfo {
    color: #012753;
    font-size: small; /* Make poster info smaller */
}

.postContent {
    border: #D6D6E7 solid 1px;
    padding: 5px;
}

.repliesContainer {
    margin-top: 10px; /* Add space between post content and replies */
}

.replyDelete {
    color: #AABFD7;
}

.replyHeader {
    color: #AABFD7;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5px; /* Add space between header and content */
}

.replyPosterInfo {
    color: white;
    font-size: small; /* Make reply poster info smaller */
    margin-right: 10px; /* Add space between poster info and content */
}

.replyContent {
    font-weight: bold;
    border: #AABFD7 1px solid;
    padding: 5px;
}

.replySinglePost {
    background-color: rgba(50, 84, 144, .75);
    padding: 5px;
    margin-top: 5px;
}


/* Media Query for Responsive Design */
@media (max-width: 768px) {
    .forumBox {
        width: 90%; /* Adjust width for smaller screens */
    }
}

.forumButton {

    align-items: center;
    appearance: none;
    background-color: #012753;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    height: 35px;
    width: 175px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin-bottom: 20px;
    }
    
.forumButton:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.forumButton:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.forumButton:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

button:hover {
    transform: translateY(-0.25vh);
    color:#AABFD7;
}