@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.chatterMain {
    font-family: quicksand;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    display: flex;
    justify-content: space-around;
    margin: 15px 0px;
}

.chatTitle {
    font-family: quicksand;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}

.chatTitle2 {
    font-family: quicksand;
    font-size: 1.5rem;
    color: #012753;
    margin: 0px 10px 20px 10px;
}

.newPostInfo {
    color: #ffffff;
    font-family: quicksand;
    font-size: .85rem;

}

.chatterBoxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 20px 10px 20px;
}

.chatBoxDiv {
    display: flex;
    flex-direction: row;
    margin: 10px 30px;
    align-items: center;
    justify-content: center;
}

.singleBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #012753;
    margin: 5px 15px 30px 15px;
    padding: 5px;
    border: #AABFD7 10px solid;
    color: white;
    font-weight: bold;
    width: 300px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    transition: box-shadow .15s,transform .15s;
}

.messagesWrapper {
    padding: 10px;
    border: #AABFD7 10px solid;
    margin: 15px ;
}

.singleBox:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.singleBox:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.singleBox:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

@keyframes wiggle {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    75% {
        transform: rotate(-10deg);
    }
}

.newPostsAlert {
    animation: wiggle 1s infinite;
}

@media (max-width: 768px) {
    .chatterMain, .chatBoxDiv {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .singleBox {
        margin-bottom: 20px;
    }

    .chatBoxDiv {
        margin-bottom: 0px;
    }

    .chatterBoxes {
        margin-bottom: 0px;
    }
}

@media (max-width: 440px) {
    .singleBox {
        margin-bottom: 20px;
        width: 300px;
    }

}