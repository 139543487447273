@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');


.welcome-section {
    height: 80vh;
    display: flex;
    font-family: quicksand;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    
}

.homeTitle {
    font-family: quicksand;
    font-size: 4.5rem;
    color: #012753;
    margin: 0px 10px;
}
  
.scroll-arrow {
    font-size: 4.5rem;
    margin-top: 20px;
    cursor: pointer;
    color: #012753;
}
  
.connection-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #6798DB;
    color: #333;
}

.homeTitle2 {
    font-family: quicksand;
    font-size: 3rem;
    color: white;
    margin: 5px 10px;
}

.paraBox {
    width: 80%;
}

.homePara {
    font-family: quicksand;
    font-size: 1.5rem;
    color: #012753;
    margin: 5px 10px;
}

  
.homeButton {
    align-items: center;
    appearance: none;
    background-color: white;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#012753 0px -3px 0px inset;
    box-sizing: border-box;
    color: #012753;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    height: 35px;
    width: 150px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin-top: 30px;
}

.homeButton:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.homeButton:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.homeButton:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

.homeButton:hover {
    transform: translateY(-0.25vh);
    color:#AABFD7;
}

.scroll-to-top {
    position: fixed;
    top: 20px; /* Adjust the top position as needed */
    left: 20px; /* Adjust the left position as needed */
    cursor: pointer;
  }
  
  .arrow-icon {
    font-size: 24px; /* Adjust the font size as needed */
    color: #012753; /* Set the color as per your design */
  }