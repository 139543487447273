.logRegForms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 68vh;
}

.navButton5 {

  align-items: center;
    appearance: none;
    background-color: #012753;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    height: 35px;
    width: 175px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
  }
  
.navButton5:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}
  
.navButton5:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}
  
.navButton5:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.navButton5:hover {
  transform: translateY(-0.25vh);
  color:#AABFD7;
}

.titleLog {
  font-size: x-large;
  font-weight: bold;
  color: #012753;
  padding: 0px 0px 5px 0px;
  margin: 10px;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #012753 !important;
}

@media (max-width: 767px){
  
    .logRegForms{
      flex-direction: column;
    }

  }