@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.navbar {
    background-color: whitesmoke;
    background-image: url("assets/clubBackground.svg");
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: quicksand;
    padding: 5px 0px;
    height: 170px;
}

.leftNav {
    padding: 0px 10px;
}

.navList {
    flex-direction: row;
    justify-content: space-around;
}

.navList1 {
  flex-direction: row;
  justify-content: space-around;
}

.rowNav1 , .rowNav2 {
  flex-direction: row;
}

.listItem {
    margin: 10px;
}

.navButton {

    align-items: center;
    appearance: none;
    background-color: #012753;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    height: 35px;
    width: 175px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    }
    
.navButton:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.navButton:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.navButton:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

button:hover {
    transform: translateY(-0.25vh);
    color:#AABFD7;
}

@media (min-width:767px) {
    .plainLogo {
      display: none;
    }
  }
  
  
    @media (max-width: 767px){
  
      .leftNav{
        display: none;
      }
  
      .centerNav{
        display: none;
      }
      
      .plainLogo{
        width: 70%; /* Adjust the percentage based on your preference */
        height: auto; /* Maintain aspect ratio */
        margin: 0 auto; /* Center-align horizontally */
        align-items: center;
      }

  
    }

    .mobile-menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }
    
    .mobile-menu {
      background-color: #AABFD7;
      border-radius: 5px;
      padding: 20px;
    }
    
    .mobile-menu-list {
      list-style: none;
      padding: 0;
    }
    
    .mobile-menu-item {
      margin-bottom: 10px;
      text-decoration: none;
      color: #012753;
      font-size: 18px;
      display: block;
      font-weight: bold;
    }

    .mobile-menu-item a {
      text-decoration: none;
      color: #012753;
      font-size: 18px;
      display: block;
    }

    .mobile-menu-item :hover {
      color: white;
    }
    
    .mobile-menu-item a:hover {
      color: white;
    }

    .mobile-menu-button {
      display: none; /* Hide the button by default */
      cursor: pointer;
    }
    
    .mobile-menu-button .hamburger {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      background-color: #012753;
      padding: 5px;
      border-radius: 5px;
      margin-left: 5px;
    }
    
    .mobile-menu-button .bar {
      width: 30px;
      height: 3px;
      align-self: center;
      background-color: white;
      border-radius: 5px;
      margin: 4px 0px 4px 0px;
      transition: 0.4s;
    }
    
    .mobile-menu-button .bar.open:nth-child(1) {
      transform: rotate(-45deg) translate(5px, 5px);
    }
    
    .mobile-menu-button .bar.open:nth-child(2) {
      opacity: 0;
    }
    
    .mobile-menu-button .bar.open:nth-child(3) {
      transform: rotate(45deg) translate(5px, -5px);
    }
    
    @media (max-width: 767px) {
      .mobile-menu-button {
        display: block; /* Display the button on small screens */
      }
    }