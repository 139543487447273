@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

/* .members {
    display: flex;
    font-family: 'Quicksand', sans-serif;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.memStick {
    display: flex;
    flex-direction: row;
    align-items: flex-start; 
}

.memberTitle {
    font-family: 'Quicksand', sans-serif;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    background-color: white;
    font-weight: 400;
}

.memberPara {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    color: #012753;
    margin: 5px 10px;
}

.userName:hover {
    color: #6798DB;
}

.sticky {
    margin-left: 20px; 
}


.sticky-note {
    background-color: #AABFD7;
    border: 2px solid #012753;
    color: #012753;
    padding: 20px;
    max-width: 300px;
    position: relative;
    font-size: large;
    font-family: quicksand;
}



.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: none;
    border: none;
    color: #012753;
    font-size: 14px;
}

.close-btn:hover {
    color: white;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    color: #012753;
    background-color: #AABFD7;
  } */

  .members {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  .members .memberPara {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    gap: 20px; /* Adjust the gap between grid items */
  }

  /* Media query for small screens */
  @media (max-width: 768px) {
    .members .memberPara {
      grid-template-columns: 1fr; /* One column for small screens */
    }
  }

  .memberBox {
    background-color: rgba(170, 191, 215, 0.25);
    padding: 15px;
  }
  
  .memberTitle {
    font-family: 'Quicksand', sans-serif;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    background-color: white;
    font-weight: 400;
  }
  
  .memberPara {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    color: #012753;
    margin: 5px 10px;
  }
  
  .userName:hover {
    color: #6798DB;
    transform: translateY(-0.25vh);

  }
  
  .sticky {
    margin-left: 20px; /* Adjust this value to set the distance between the member and sticky note */
  }
  
  .sticky-note {
    background-color: #AABFD7;
    border: 2px solid #012753;
    color: #012753;
    padding: 20px;
    max-width: 300px;
    position: relative;
    font-size: large;
    font-family: quicksand;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: none;
    border: none;
    color: #012753;
    font-size: 14px;
  }
  
  .close-btn:hover {
    color: white;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    color: #012753;
    background-color: #AABFD7;
  }
  