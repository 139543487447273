
.user-dash-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 15px 15px;
}

.dashSideLeft {
    background-color: white;
    color: #012753;
    margin: 10px 10px 0px 10px;
    max-width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashSideRight {
    background-color: #AABFD7;
    color: #012753;
    margin: 10px 10px 0px 10px;
    max-width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.natureImg {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.h3Dash {
    font-family: quicksand;
    font-size: 1.5rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}

.h4Dash {
    font-family: quicksand;
    font-size: 1rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}

.tasks-container {
    border: 2px solid #AABFD7;
    padding: 10px;
    margin-top: 10px;
    box-sizing: border-box;
    min-width: 200px; 
    min-height: 300px; 
  }

.dashTitle {
    font-family: quicksand;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}

.dashPara {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    color: #012753;
    margin: 5px 10px;
}

.title5 {
    margin: 10px 5px;
}

.tasks {
    background-color: white;
    padding: 10px;
    margin: 0px 1px;
}


.user-bulletin-container {
    display: flex;
    background-image: url("assets/cork.svg");
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 15px;
    overflow-y: auto;
    color: #012753;
    width: 90%;
}

.bbTitle {
    font-family: quicksand;
    background-color: white;
    align-self: center;
    font-size: 2.5rem;
    color: #012753;
    margin: 15px 10px;
    padding: 10px;
    top: 0;
    z-index: 1;
    width: 90%;
}

.bbPosts {
    background-image: url("assets/postit.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    max-width: 800px;
    height: 200px;
    padding: 40px 0px 5px 0px;
    color: #012753;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    text-align: center;
    background-color: rgba(255, 255, 255, 0);
    overflow-y: auto;
    max-height: 400px;
    margin: 5px;
}

.bbPosts p {
    margin: 5px;
    padding: 5px 5px 0px 5px;
    font-size: 16px;
    max-width: 65%;
    white-space: pre-line;
    overflow: scroll;
    max-height: 80%;
}

.bulletinSide {
    height: 450px;
    display: flex;
    justify-content: center;

}

.thumbtack {
    background-image: url("assets/thumbtack.svg");
}


.forumBoxBb {
    width: 75%;
    max-width: 600px;
    justify-content: center;
    align-items: center;
}

.forumPostBb {
    background-color: #ffffff; /* Change to white background */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow */
    margin-bottom: 20px; /* Add some space between posts */
    padding: 15px 20px; /* Add padding */

}


.forumPostBb > div {
    margin-bottom: 10px; /* Add space between post elements */
}

.postHeaderBb {
    color: #012753;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px; 
}

.posterInfoBb {
    color: #012753;
    font-size: small; 
}

.postContentBb {
    border: #D6D6E7 solid 1px;
    padding: 5px;
}


@media (max-width: 768px) {
    .user-dash-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
    }

    .user-bulletin-container {
        max-width: 90%;
        align-self: center;
        justify-content: center;

    }

    .bbPosts  {
        width: 100%; 
    }
    .profPara {
        max-width: 60%;
        height: 100%;
    }

    .dashSide, .dashSideLeft, .dashSideRight, .bulletinSide {
        max-width: 100%;
    }

    .tasks-container {
        min-width: 100%; 
        min-height: 200px; 
    }
}

.clickText {
    cursor: pointer;
    color: #012753;
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
  
  .clickText:hover {
    color: white; 
  }


.navButtonTodo {

    align-items: center;
    appearance: none;
    background-color: #012753;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    margin-top: 20px;
    height: 35px;
    width: 175px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    }
    
.navButtonTodo:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.navButtonTodo:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.navButtonTodo:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

button:hover {
    transform: translateY(-0.25vh);
    color:#AABFD7;
}

.span {
    background-color: #AABFD7;
}
