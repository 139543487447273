.user-profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0px 15px;
}

.user-profile-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0px 15px;
}

.userProfButton {
    margin-top: 15px;
}

.profileSide {
    display: flex;
    flex-direction: column;
    background-color: #AABFD7;
    color: #012753;
    margin: 10px 10px 10px 10px;
    max-width: 75%;
padding-bottom: 10px;
}


.profTitle {
    font-family: quicksand;
    font-size: 2.5rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
}



.profPara {
    display: flex;
    flex-direction: column;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    color: #012753;
    align-items: center;
    align-self: center;
    max-width: 90%;
}




@media (max-width: 768px) {
    .user-profile-container, .user-profile-container2, .profileSide {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .profPara {
        width: 100%;
        height: auto;
    }

}

.navButtonTodo {

    align-items: center;
    appearance: none;
    background-color: #012753;
    border-radius: 0px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#AABFD7 0px -3px 0px inset;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-family: "quicksand",monospace;
    font-weight: bold;
    margin-top: 20px;
    height: 35px;
    width: 175px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    }
    
.navButtonTodo:focus {
    box-shadow: #AABFD7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
}
    
.navButtonTodo:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #AABFD7 0 -3px 0 inset;
    transform: translateY(-2px);
}
    
.navButtonTodo:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

button:hover {
    transform: translateY(-0.25vh);
    color:#AABFD7;
}
