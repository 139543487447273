@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

.footer {
    background-color: #f5f5f5;
    background-image: url("assets/clubBackground.svg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: quicksand;
    padding: 10px;
    margin-top: auto;
}

.footerContact:hover {
    transform: translateY(-0.25vh);
    color:white;
    font-weight: bold;
}

.footerBg {
    font-size: medium;
    background-color: #AABFD7;
    font-weight: bold;
    color: #012753;
    padding: 15px
}
