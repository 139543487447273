
/* .calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  .header {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: center;
  }
  
  .day {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  
  .current-day {
    background-color: #add8e6;
  }
  
  .blank {
    background-color: transparent;
  }
   */

   /* calendar.css */
.small-calendar {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    width: 300px;
    margin: 20px 10px 10px 10px;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 5px;
  }
  
  .calendar-day {
    flex: 1;
    text-align: center;
    padding: 5px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  
  .calendar-day:last-child {
    border-right: none;
  }
  
  .calendar-week {
    display: flex;
  }
  
  .current-day {
    background-color: #AABFD7;
  }

  .calTitle {
    font-family: quicksand;
    font-size: 1rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
  }
  