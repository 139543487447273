.daily-weather-container {
    background-image: url("assets/chatterBg3.svg");
    color: #012753;
    margin: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  
  .weather-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px 10px 5px;
  }
  
  .weather-icon {
    align-self: center;
    margin-top: 5px

  }
  
  .weather-info {
    text-align: center;
    max-width: 75%;
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .daily-weather-container {
      max-width: 100%;
    }
  }
  
  .weatherTitle {
    font-family: quicksand;
    font-size: 1rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
  }

  .weatherDeets {
    font-family: quicksand;
    font-size: 1rem;
    color: #012753;
    margin: 0px 10px;
    padding: 5px;
    font-weight: bold;
  }